<template>
  <div>
    <header class="fixed-top p-0" ref="header">
      <div class="user-inputs pt-5 pb-3">
        <UserInputs @on-submit="onSubmit" />
      </div>
      <DetectionSummary
          v-if="searchResults.processCompleted"
          :searchResults="searchResults"
      />
    </header>
    <div ref="detectedImages">
      <template v-if="searchResults.processCompleted && searchResults.bannedWordsOnImages.length > 0">
        <DetectedImage
            v-for="(bannedWordsOnImage, index) in searchResults.bannedWordsOnImages"
            :bannedWordsOnImage="bannedWordsOnImage"
            :key="index"
        />
      </template>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UserInputs from "@/components/UserInputs";
import DetectionSummary from "@/components/DetectionSummary";
import DetectedImage from "@/components/DetectedImage";

export default {
  name: "DetectionTool",
  components: {
    UserInputs,
    DetectionSummary,
    DetectedImage,
  },
  data() {
    return {
      searchResults: {},
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateHeaderHeight);
  },
  updated() {
    this.updateHeaderHeight();
  },
  methods: {
    onSubmit(searchResults) {
      this.searchResults = searchResults;
    },
    updateHeaderHeight() {
      this.$refs.detectedImages.style.marginTop = this.$refs.header.clientHeight + "px";
    },
  },
};
</script>

<style>
header {
  background-color: #eff6ee;
  position: relative;
}

.user-inputs {
  background-color: #273043;
}
</style>
