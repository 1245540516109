import allowedDevices from "@/config/allowedDevices";
import urlValidator from "@/helpers/urlValidator";
import dateFormat from "dateformat";
import axios from "axios";

export default {
  crawlWebsite(serviceBaseUrl, targetUrl, device) {
    return new Promise((resolve, reject) => {
      if (!~allowedDevices.list.indexOf(device)) {
        return reject("device term is not recognized: " + device);
      }

      if (!urlValidator.isUrlValid(targetUrl)) {
        return reject("targetUrl provided is not valid: " + targetUrl);
      }

      let bucketName = process.env.VUE_APP_CRAWLER_COMMON_BUCKET_NAME;
      let currDateTime = dateFormat(new Date(), "yyyymmddHHMMss");
      let queryString = new URLSearchParams({
        submittedMediaUrlId: currDateTime,
        url: encodeURIComponent(targetUrl),
        bucket: bucketName,
        mid: currDateTime,
        device: device,
      }).toString();

      axios
        .get(serviceBaseUrl + "?" + queryString)
        .then((response) => {
          resolve(response.data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};
