<template>
  <b-form @submit.stop.prevent="onSubmit" class="px-2 px-sm-5 pl-md-5 pl-lg-5">
    <fieldset :disabled="formInputs.formDisabled">
      <b-row class="form-group align-items-center mb-0">
        <label
          class="font-weight-bold d-none d-xs-block d-md-block col-sm-1 col-lg-1 col-xl-2 text-right mb-0"
          >URL</label
        >
        <b-col xl="7" md="9" sm="12" class="px-sm-0 pl-md-0">
          <b-form-input
            type="text"
            v-model="formInputs.mediaUrl"
            placeholder="URL"
            autocomplete="off"
            :state="urlState()"
          ></b-form-input>
        </b-col>
        <b-col
          lg="2"
          md="2"
          sm="12"
          class="mb-n3 mb-sm-0 pt-3 pt-sm-3 pt-md-0 px-sm-0 pl-md-3 mt-sm-6 mt-sm-0"
        >
          <b-button
            type="submit"
            variant="danger"
            class="d-none d-md-block col-sm-12 col-md-9 col-lg-6"
            id="btn-submit"
            >実行</b-button
          >
        </b-col>
      </b-row>
      <b-row class="form-group m-md-1" v-if="$v.formInputs.mediaUrl.$error">
        <b-form-invalid-feedback
          class="col-xl-7 offset-xl-2 col-lg-9 offset-lg-1 d-block mb-n3 text-center error"
        >
          <template v-if="!$v.formInputs.mediaUrl.required"
            >URLの入力は必須です</template
          >
          <template v-if="!$v.formInputs.mediaUrl.url">
            URLの形式が正しくありません
          </template>
        </b-form-invalid-feedback>
      </b-row>
      <b-row
        class="form-group align-items-center pt-3 pt-sm-0 pt-md-3 px-3 px-sm-0"
      >
        <b-col
          xl="2"
          offset-xl="2"
          md="3"
          offset-md="1"
          sm="6"
          offset-sm="0"
          cols="6"
          class="pl-2 pr-0 pl-sm-0 text-md-left"
        >
          <label class="checkbox-label ml-auto mb-0">
            画像ごとにチェックする
            <b-form-checkbox
              size="lg"
              class="d-none d-lg-inline-block ml-sm-1 ml-md-2 mr-n2 align-middle"
              v-model="formInputs.checkKeywordOnEachImage"
            ></b-form-checkbox>
            <b-form-checkbox
              class="d-inline d-sm-inline d-md-inline d-lg-none ml-1 ml-sm-1 ml-md-1 mr-n2"
              v-model="formInputs.checkKeywordOnEachImage"
            ></b-form-checkbox>
          </label>
        </b-col>
        <b-col
          xl="3"
          lg="4"
          md="3"
          sm="5"
          cols="5"
          class="text-center pl-md-4 ml-3 ml-sm-0"
        >
          <label class="checkbox-label ml-auto mb-0">
            人体部位を除外
            <b-form-checkbox
              size="lg"
              class="d-none d-lg-inline-block ml-2 mr-n2 align-middle"
              v-model="formInputs.excludeNGWords"
            ></b-form-checkbox>
            <b-form-checkbox
              class="d-inline d-sm-inline d-md-inline d-lg-none ml-2 mr-n2"
              v-model="formInputs.excludeNGWords"
            ></b-form-checkbox>
          </label>
        </b-col>
        <b-col
          cols="12"
          lg="2"
          offset-lg="0"
          md="2"
          offset-md="1"
          sm="12"
          class="text-right px-0 px-sm-0 mt-3 mt-md-0"
        >
          <select
            class="d-none d-md-inline-block col-sm-12 col-md-10 col-lg-7"
            v-model="formInputs.selectedDevice"
          >
            <option
              v-for="(device, index) in formInputs.deviceOptions"
              :key="index"
              :value="device"
            >
              {{ device }}
            </option>
          </select>
          <select
            class="d-md-none custom-select col-sm-12 col-md-10 col-lg-7"
            v-model="formInputs.selectedDevice"
          >
            <option
              v-for="(device, index) in formInputs.deviceOptions"
              :key="index"
              :value="device"
            >
              {{ device }}
            </option>
          </select>
        </b-col>
      </b-row>
      <b-row class="form-group d-md-none mb-0 pt-1">
        <b-col class="px-sm-0">
          <b-button
            type="submit"
            variant="danger"
            class="wd-100 col-sm-12 col-md-9 col-lg-6"
            id="btn-submit"
            >実行</b-button
          >
        </b-col>
      </b-row>
    </fieldset>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";
import { indexOf, map } from "lodash";
import NProgress from "nprogress";
import BannedWordsDescription from "@/services/BannedWordsDescription";
import SamuraiApi from "@/services/SamuraiApi";
import PuppeteerApi from "@/services/PuppeteerApi";
import VisionApi from "@/services/VisionApi";
import allowedDevices from "@/config/allowedDevices";

export default {
  name: "UserInputs",
  mixins: [validationMixin],
  data() {
    return {
      formInputs: {
        mediaUrl: "",
        checkKeywordOnEachImage: false,
        excludeNGWords: false,
        selectedDevice: allowedDevices.default,
        deviceOptions: allowedDevices.list,
        formDisabled: false,
      },
      imagesRelativePath: [],
      bannedWordsDescription: [],
      bannedWordsOnly: [],
    };
  },
  validations: {
    formInputs: {
      mediaUrl: {
        required,
        url,
      },
    },
  },
  created() {
    this.getBannedWordsDescription();
  },
  methods: {
    urlState() {
      const { $dirty, $error } = this.$v.formInputs.mediaUrl;
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.formInputs.$anyError) {
        return;
      }

      let searchResults = {
        processCompleted: false,
        error: "",
        bannedWordsOnImages: [],
      };
      this.emitSearchResults(searchResults);

      // Start of progress bar
      NProgress.start();
      this.formInputs.formDisabled = true;

      let errorMessage = "";
      this.imagesRelativePath = [];
      if (this.formInputs.checkKeywordOnEachImage) {
        try {
          let apiResponse = await SamuraiApi.getWebpageImages(this.formInputs.mediaUrl, this.formInputs.selectedDevice);
          this.imagesRelativePath = this.getUploadPaths(apiResponse);
        } catch (reason) {
          console.error("SamuraiAPI failed: ", reason);
          errorMessage = this.getErrorMessage();
        }
      } else {
        try {
          let apiResponse = await PuppeteerApi.getWebpageScreenshot(this.formInputs.mediaUrl, this.formInputs.selectedDevice);
          this.imagesRelativePath = [apiResponse.IMG];
        } catch (reason) {
          console.error("PuppeteerAPI failed: ", reason);
          errorMessage = this.getErrorMessage();
        }
      }

      let bannedWordsOnImages = [];
      try {
        let allTextAnnotations = await VisionApi.getTextsFromRelativeGcsImagesPath(this.imagesRelativePath);
        bannedWordsOnImages = this.getBannedWordsOnImages(allTextAnnotations);
      } catch (reason) {
        console.error("VisionAPI failed: ", reason);
        errorMessage = this.getErrorMessage();
      }

      searchResults = {
        processCompleted: true,
        error: errorMessage,
        bannedWordsOnImages: bannedWordsOnImages,
      };
      this.emitSearchResults(searchResults);

      this.formInputs.formDisabled = false;
      // End of progress bar
      NProgress.done();
    },
    emitSearchResults(searchResults) {
      this.$emit("on-submit", searchResults);
    },
    async getBannedWordsDescription() {
      try {
        let rows = await BannedWordsDescription.get();
        for (let index in rows) {
          let bannedWordDescription = rows[index];
          this.bannedWordsDescription.push(bannedWordDescription);
          this.bannedWordsOnly.push(bannedWordDescription.BannedWord);
        }
      } catch(reason) {
        console.error("BannedWordsDescription failed: ", reason);
      }
    },
    getBannedWordsOnImages(allTextAnnotations) {
      let bannedWordsOnImages = [];
      if (allTextAnnotations.length <= 0) {
        return bannedWordsOnImages;
      }

      for (let imageIndex in allTextAnnotations) {
        imageIndex = parseInt(imageIndex);
        let textAnnotations = allTextAnnotations[imageIndex];

        let imageRelativePath = this.imagesRelativePath[imageIndex];
        let detectedBannedWords = [];
        for (let annotationIndex in textAnnotations) {
          let textAnnotation = textAnnotations[annotationIndex]
          let bannedWordIndex = indexOf(
              this.bannedWordsOnly,
              textAnnotation.description
          );
          if (!~bannedWordIndex) {
            continue;
          }

          let isBodyPart =
              this.bannedWordsDescription[bannedWordIndex].IsBodyPart;
          let excludeBodyPart = this.formInputs.excludeNGWords;
          if (isBodyPart && excludeBodyPart) {
            continue;
          }

          let bannedWordDescription =
              this.bannedWordsDescription[bannedWordIndex].Description.replace(/\n/g, "<br />");

          detectedBannedWords.push({
            bannedWord: {
              text: textAnnotation.description,
              description: bannedWordDescription,
            },
            textLocation: {
              annotationIndex: annotationIndex,
              vertices: textAnnotation.bounding_poly.vertices,
            },
          });
        }

        bannedWordsOnImages.push({
          imageIndex: imageIndex,
          relativePath: imageRelativePath,
          detectedBannedWords: detectedBannedWords,
        });
      }
      return bannedWordsOnImages;
    },
    getUploadPaths(apiResponse) {
      return map(apiResponse, (image) => {
        return image.uploadPath;
      });
    },
    getErrorMessage() {
      return this.formInputs.checkKeywordOnEachImage
          ? "このサイトの画像は読み込めません"
          : "画像ごとにチェックするを選択してください";
    },
  },
};
</script>

<style scoped>
form {
  color: #fff;
  height: 100%;
}

.error {
  font-size: 1rem;
}

.checkbox-label {
  word-break: keep-all;
}

select {
  background: #fff url(../assets/caret-dropdown.svg) 100% 50% no-repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

@media only screen and (min-width: 768px) {
  select {
    padding: 0 0.125rem;
    border-radius: 0.25rem;
  }
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.8 !important;
}
</style>
