import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "../components/PageNotFound";
import DetectionTool from "../views/DetectionTool.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "DetectionTool",
    component: DetectionTool,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
