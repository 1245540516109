import { isArray } from "lodash/lang";
import axios from "axios";

export default {
  getTextsFromRelativeGcsImagesPath(imagesPath) {
    return new Promise((resolve, reject) => {
      if (!isArray(imagesPath)) {
        return reject("imagesPath must be an array");
      }
      if (imagesPath.length <= 0) {
        return reject("imagesPath cannot be empty");
      }

      let bucketName = process.env.VUE_APP_CRAWLER_COMMON_BUCKET_NAME;
      let baseUrl = process.env.VUE_APP_DEEPTRACK_BACKEND_BASE_URL;
      let endpoint = "image_ocr";

      axios
        .post(baseUrl + endpoint, null, {
          params: {
            bucketName,
            imagesPath,
          },
        })
        .then((response) => {
          if (!response.data || response.data.Status !== 200) {
            let message =
              response.data && response.data.Message
                ? response.data.Message
                : response.data;
            throw new Error("problem with response: " + message);
          }
          resolve(response.data.Message);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};
