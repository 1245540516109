import axios from "axios";

let status = {
  isLoaded: false,
  isLoading: false,
  loadingPromise: null,
};

let bannedWordsDescription = [];

export default {
  get() {
    if (status.isLoaded) {
      return new Promise((resolve) => {
        resolve(bannedWordsDescription);
      });
    } else if (status.isLoading) {
      return status.loadingPromise;
    }
    let baseUrl = process.env.VUE_APP_DEEPTRACK_BACKEND_BASE_URL;
    let endpoint = "banned_words_description";
    let targetUrl = baseUrl + endpoint;
    return (status.loadingPromise = new Promise((resolve, reject) => {
      status.isLoading = true;
      axios
        .get(targetUrl)
        .then((response) => {
          if (!response.data || response.data.Status !== 200) {
            let message =
              response.data && response.data.Message
                ? response.data.Message
                : response.data;
            throw new Error("problem with response: " + message);
          }
          bannedWordsDescription = response.data.Message;
          status.isLoaded = true;
          status.isLoading = false;
          resolve(bannedWordsDescription);
        })
        .catch((reason) => {
          status.isLoading = false;
          reject(reason);
        });
    }));
  },
};
