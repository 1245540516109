<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import BannedWordsDescription from "@/services/BannedWordsDescription";

export default {
  created() {
    BannedWordsDescription.get();
  },
};
</script>

<style>
#app {
  font-family: Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  background-color: #eff6ee;
  font-size: 0.83rem;
}

@media only screen and (min-width: 992px) {
  body {
    font-size: 0.9rem;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #f02d3a !important;
  background-color: #f02d3a !important;
}

.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  opacity: 0.8 !important;
}

.popover {
  height: 300px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.popover.b-popover {
  color: #fff !important;
  border-color: #f02d3a !important;
  border-radius: 0 !important;
}

.popover-header {
  background-color: #f02d3a !important;
  border-radius: 0 !important;
}

.popover-body {
  color: #707070 !important;
  font-size: 1rem !important;
  max-height: 250px;
  overflow-y: scroll;
}

.popover-body::-webkit-scrollbar {
  width: 12px;
}

.popover-body::-webkit-scrollbar-track {
  box-shadow: 0 0 0 transparent inset;
  -webkit-box-shadow: 0 0 0 transparent inset;
  border-radius: 10px;
}

.popover-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

.popover .arrow:after,
.bs-popover-bottom > .arrow:after,
.bs-popover-bottom .popover-header:before {
  border-bottom-color: #f02d3a !important;
}

.popover .arrow::before {
  border-bottom-color: #f02d3a !important;
  border-top-color: #f02d3a !important;
}
</style>
