import Vue from "vue";
import { validationMixin } from "vuelidate";
const { required, url } = require("vuelidate/lib/validators");

export default {
  isUrlValid(urlInput) {
    let vuelidateHelper = new Vue({
      mixins: [validationMixin],
      data() {
        return {
          urlInput: "",
        };
      },
      validations: {
        urlInput: {
          required,
          url,
        },
      },
    });
    vuelidateHelper.urlInput = urlInput;
    return !vuelidateHelper.$v.urlInput.$invalid;
  },
};
