<template>
  <b-row align-v="center" align-h="center">
    <b-col
      xl="9"
      md="11"
      cols="11"
      class="summary my-3"
      :class="summaryClassObject"
    >
      <template v-if="searchResults.error">{{ searchResults.error }}</template>
      <template v-else-if="numberOfDetectedKeywords <= 0">「NGキーワードは検知されませんでした」</template>
      <template v-else>
        <b-col lg="12" class="px-2 pt-2 pb-0">
          検知キーワード総数:<span> {{ numberOfDetectedKeywords }}</span>
        </b-col>
        <b-col lg="12" class="px-2 pt-0 pb-2">
          検知したキーワード:
          <span
              class="m-1"
              v-for="(bannedWordsDetail, word, index) in bannedWordsDetails"
              :key="index"
          >
            <a
                href="#"
                :id="'popover-target-' + word"
                @click.prevent="scrollToWord(bannedWordsDetail.annotationIndices, bannedWordsDetail.imageIndex)"
            >{{ word }} ({{ bannedWordsDetail.annotationIndices.length }})</a
            >
            {{ lastBannedWord !== word ? "," : "" }}
            <b-popover
                v-show="bannedWordsDetail.description"
                :target="'popover-target-' + word"
                triggers="hover"
                placement="bottom"
            >
              <template #title>検知したキーワード{{ word }}</template>
              <div v-html="bannedWordsDetail.description"></div>
            </b-popover>
          </span>
        </b-col>
      </template>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DetectionSummary",
  props: {
    searchResults: {
      type: Object,
      required: true,
    },
  },
  computed: {
    summaryClassObject() {
      return {
        "error": !!this.searchResults.error,
        "text-center": !!this.searchResults.error || this.numberOfDetectedKeywords <= 0,
      };
    },
    numberOfDetectedKeywords() {
      let numberOfDetectedKeywords = 0;
      for (let index in this.searchResults.bannedWordsOnImages) {
        let bannedWordsOnImage = this.searchResults.bannedWordsOnImages[index];
        let detectedBannedWords = bannedWordsOnImage.detectedBannedWords;
        numberOfDetectedKeywords += detectedBannedWords.length;
      }
      return numberOfDetectedKeywords;
    },
    bannedWordsDetails() {
      let bannedWordsDetails = {};
      for (let bannedWordsIndex in this.searchResults.bannedWordsOnImages) {
        let bannedWordsOnImage = this.searchResults.bannedWordsOnImages[bannedWordsIndex];
        let detectedBannedWords = bannedWordsOnImage.detectedBannedWords;
        for (let bannedWordIndex in detectedBannedWords) {
          let detectedBannedWord = detectedBannedWords[bannedWordIndex];
          let text = detectedBannedWord.bannedWord.text;
          let annotationIndexInt = parseInt(detectedBannedWord.textLocation.annotationIndex);
          if (bannedWordsDetails[text]) {
            bannedWordsDetails[text].annotationIndices.push(annotationIndexInt);
          } else {
            bannedWordsDetails[text] = {
              imageIndex: bannedWordsOnImage.imageIndex,
              description: detectedBannedWord.bannedWord.description,
              annotationIndices: [annotationIndexInt],
            }
          }
        }
      }
      return bannedWordsDetails;
    },
    lastBannedWord() {
      return Object.keys(this.bannedWordsDetails)[
        Object.keys(this.bannedWordsDetails).length - 1
      ];
    },
  },
  methods: {
    scrollToWord(indices, imageIndex) {
      console.log("clickSummaryItem > indices: ", indices);

      let nextIndex = (indices.scrolledTo > -1 ? indices.scrolledTo : -1) + 1;
      if (nextIndex >= indices.length) nextIndex = 0;
      indices.scrolledTo = nextIndex;

      let wordIndex = indices[nextIndex];
      let wordHighlight = document.querySelector("#word_" + imageIndex + "_" + wordIndex);

      let coords = this.getElemCoords(wordHighlight);
      let stickyHeaderHeight =
          document.querySelector("header.fixed-top").clientHeight;
      coords.top = coords.top - stickyHeaderHeight;

      window.scrollTo(coords);
    },
    getElemCoords(elem) {

      let box = elem.getBoundingClientRect();

      let body = document.body;
      let docEl = document.documentElement;

      let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      let scrollLeft =
          window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      let clientTop = docEl.clientTop || body.clientTop || 0;
      let clientLeft = docEl.clientLeft || body.clientLeft || 0;

      let top = box.top + scrollTop - clientTop;
      let left = box.left + scrollLeft - clientLeft;

      return {
        top: Math.round(top),
        left: Math.round(left),
      };
    },
  },
};
</script>

<style scoped>
.summary {
  background-color: #fff;
  border: 2px solid #9197ae;
  padding: 1rem;
  font-size: 1rem;
  z-index: 1;
  text-align: left;
  box-shadow: 0 0 3px #000;
}

.summary span a {
  color: #f02d3a;
  white-space: nowrap;
}

.error {
  color: #f80808;
}
</style>
