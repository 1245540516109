<template>
  <b-row align-h="center">
    <b-col xl="9" md="11" cols="11" class="px-0">
      <div class="image-holder">
        <img
          :src="publicImagePath"
          class="img-width-banned-words"
          ref="detectedImage"
        />
        <div
          v-for="(bannedWord, index) in bannedWordsOnImage.detectedBannedWords"
          class="word-highlight"
          :id="'word_' + bannedWordsOnImage.imageIndex + '_' + bannedWord.textLocation.annotationIndex"
          :style="coordsToCss(bannedWord.textLocation.vertices)"
          :key="index"
        ></div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DetectedImage",
  props: {
    bannedWordsOnImage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgWidth: 0,
      imgHeight: 0,
    };
  },
  mounted() {
    this.$refs.detectedImage.addEventListener("load", this.getDetectedImageHeightAndWidth);
  },
  destroy() {
    this.$refs.detectedImage.removeEventListener("load", this.getDetectedImageHeightAndWidth);
  },
  computed: {
    publicImagePath() {
      return (
        process.env.VUE_APP_GCS_PUBLIC_PATH +
        process.env.VUE_APP_CRAWLER_COMMON_BUCKET_NAME +
        "/" +
        this.bannedWordsOnImage.relativePath
      );
    },
  },
  methods: {
    getDetectedImageHeightAndWidth() {
      this.imgWidth = this.$refs.detectedImage.naturalWidth;
      this.imgHeight = this.$refs.detectedImage.naturalHeight;
    },
    sortNumbers(a, b) {
      return a - b;
    },
    coordsToCss(vertices) {
      if (vertices.length !== 4) {
        console.log("vertices do not contain 4 coordinates: ", vertices);
        return;
      }

      if (this.imgWidth <= 0 || this.imgHeight <= 0) {
        console.log("Image width or height must be greater than 0");
        return;
      }

      let allX = [],
        allY = [];

      for (let index in vertices) {
        let vertex = vertices[index];
        allX.push(vertex.x);
        allY.push(vertex.y);
      }

      allX.sort(this.sortNumbers);
      allY.sort(this.sortNumbers);

      let cssUnit = "%",
        startingX = allX[0],
        startingY = allY[0],
        endingX = allX[allX.length - 1],
        endingY = allY[allY.length - 1];

      let left = startingX,
        top = startingY,
        width = endingX - startingX,
        height = endingY - startingY;

      left = (left / this.imgWidth) * 100;
      top = (top / this.imgHeight) * 100;
      width = (width / this.imgWidth) * 100;
      height = (height / this.imgHeight) * 100;

      return {
        left: left + cssUnit,
        top: top + cssUnit,
        width: width + cssUnit,
        height: height + cssUnit,
      };
    },
  },
};
</script>

<style scoped>
.image-holder {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  max-width: 100%;
}

.img-width-banned-words {
  display: block;
  width: 100%;
}

.word-highlight {
  background-color: rgba(240, 45, 58, 0.3);
  border: 1px solid rgba(240, 45, 58, 0.4);
  position: absolute;
  border-radius: 4px;
  margin: -2px -4px;
  padding: 2px 4px;
  box-sizing: initial !important;
}
</style>
